
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        
    }
})
export default class Alert extends Vue {
    @Prop({required: true})
    public message!: string;

    @Prop({default: null})
    public details!: string | null;

    @Prop({required: true})
    public type!: "info" | "warning" | "error" | "success";

    private showDetails = false;

    private toggleDetails() {
        this.showDetails = !this.showDetails;
    }
}
